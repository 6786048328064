<template>
  <ContentBlockEditView disabled />
</template>

<script>
import ContentBlockEditView from '@/views/contentBlock/ContentBlockEditView'

export default {
  name: 'ContentBlockView',
  components: {
    ContentBlockEditView
  }
}
</script>
